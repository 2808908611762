


































































































































import Vue, { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';

interface VuexBindings {
  account: ApiResource.Account,
  businesses: ApiResource.Business[],
  commissions: ApiResource.Commission[],
}

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  components: {
    PrivacyV3: () => import(/* webpackChunkName: "l-dse-v3" */ '@/components/legal/dse/V3.vue'),
  },
  data() {
    return {
      error: null as Error | null,
    };
  },
  computed: {
    ...mapGetters({
      account: 'account/Account',
      businesses: 'business/List',
      commissions: 'commission/List',
    }),
    totalCount(): number {
      return this.commissions.length;
    },
    count(): (status: string) => number {
      return (status) => this.commissions
        .filter((c: ApiResource.Commission) => c.status === status)
        .length;
    },
  },
  methods: {
    signout() {
      this.$store.dispatch('auth/logout');
    },
  },
});
